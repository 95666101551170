export const copyToClipboard = str => {
  const el = document.createElement("textarea");
  el.style.position = "fixed";
  el.style.opacity = "0";
  el.value = str;
  document.body.appendChild(el);
  el.select();
  if (!navigator.clipboard) {
    document.execCommand("copy");
  } else {
    navigator.clipboard.writeText(el.value);
  }
  document.body.removeChild(el);
};

export const removeTagsFromString = str => {
  // return str.replace(/<(.|\n)*?>/g, "");
  return str.replace(/<\/(.|\n)*?>/g, "\r\n").replace(/<(.|\n)*?>/g, "");
};

export const doPoseTip = (elementSelector, variant = "bottom") => {
  const elts = document.querySelectorAll(elementSelector);
  const elt = elts[elts.length - 1];
  if (elt) {
    const rect = elt.getBoundingClientRect();
    const maincontainer = document.querySelector(".main-container");
    const rect_maincontainer = maincontainer?.getBoundingClientRect();
    const tip = document.querySelector(".intro-tip");
    switch (variant) {
      case "bottom":
        tip.style.top = `${rect.bottom + 15}px`;
        tip.style.left = `${rect.right -
          tip.offsetWidth +
          (tip.offsetWidth - rect.width) / 2}px`;
        tip.classList.add("bottom");
        tip.classList.add("visible");
        break;
      case "top":
        tip.style.top = `${rect.top -
          tip.offsetHeight -
          rect_maincontainer?.top -
          15}px`;
        tip.style.left = `${rect.right -
          tip.offsetWidth -
          rect_maincontainer?.left +
          (tip.offsetWidth - rect.width) / 2}px`;
        tip.classList.add("top");
        tip.classList.add("visible");
        break;
      case "left":
        tip.style.top = `${rect.top + rect.height / 3}px`;
        tip.style.right = `${rect.left}px`;
        tip.classList.add("left");
        tip.classList.add("visible");
        break;
      case "right":
        tip.style.top = `${rect.top + rect.height / 3}px`;
        tip.style.left = `${rect.right + 15}px`;
        tip.classList.add("right");
        tip.classList.add("visible");
        break;
    }
  }
};
