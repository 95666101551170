<template>
  <template-block
    mod-class
    content-class="create-order_unregistered"
    title-page="Новая задача"
  >
    <form
      class="small-create-task main-container"
      @submit.prevent="createRequest"
    >
      <label>
        Название задачи
        <input type="text" v-model="title" />
      </label>
      <label>
        Тип задачи
        <!-- <input v-model="title" type="text" /> -->
        <b-select v-model="selectedTypeId" class="choose w-100">
          <option v-for="type in types" :key="type.id" :value="type.id">{{
            type.title
          }}</option>
        </b-select>
      </label>
      <label>
        Описание задачи
        <textarea v-model="texts.desc" placeholder="Опишите задачу" />
      </label>

      <button class="create-task">
        <span v-if="!isOrderSubmitInProgress">Создать задачу</span>
        <b-spinner v-else small></b-spinner>
      </button>
    </form>

    <b-button
      v-if="Object.keys(error).length"
      class="mt-3"
      variant="link"
      @click="copyErrorText"
    >
      Копировать ошибку
    </b-button>
    <div id="error">{{ error }}</div>
  </template-block>
</template>

<script>
import TemplateBlock from "@/components/TepmplateBlock";
import RequestManager from "../../function/request/RequestManager";
import { copyToClipboard } from "@/utils";

export default {
  name: "OrdersCreateUnregistered",
  components: { TemplateBlock },
  data() {
    return {
      error: {},
      isErrorHidden: true,
      device: "",
      title: "Прочее",
      texts: {
        desc: "",
        shortDesc: "",
        condition: ""
      },
      isOrderSubmitInProgress: false,
      types: [
        { id: "2", title: "Вызов мастера" },
        { id: "3", title: "Замена расходных материалов" },
        { id: "5", title: "Прочее" }
      ],
      selectedTypeId: "5"
    };
  },
  computed: {
    orderCreateForm() {
      return {
        title: this.title,
        texts: this.texts,
        typeId: this.selectedTypeId,
        statusId: "1",
        priority: 1
      };
    }
  },
  methods: {
    copyErrorText() {
      copyToClipboard(document.getElementById("error").innerHTML);
    },
    async createRequest() {
      try {
        this.isOrderSubmitInProgress = true;
        await RequestManager().ordersApi.createOrderByQrCode(
          this.device,
          this.orderCreateForm
        );

        this.$bvToast.toast("Задача успешно создана", {
          variant: "success",
          title: "Успех"
        });
        copyToClipboard("Проверка связи");

        this.$router.replace({ name: "OrderWasCreate" });
      } catch (error) {
        this.error = error;

        // this.orderCreateForm.deviceId = this.device
        // await RequestManager().ordersApi.createOrderByQrCode(
        //   this.orderCreateForm,
        //   error
        // );
      } finally {
        this.isOrderSubmitInProgress = false;
      }
    }
  },
  async mounted() {
    this.device = this.$route.query.device;
    console.log(this.orderCreateForm);
    // TODO: Раскомментировать, если откроется ordersApi.getOrderTypes для незалогиненных пользователей
    /* const typesSrc = await RequestManager().ordersApi.getOrderTypes();
    this.types = typesSrc.filter(type => (["2", "3", "5"].includes(type.id))); */
  }
};
</script>

<style lang="scss" scoped>
.small-create-task {
  padding: 5px 10px;
  width: 100%;
  max-width: 500px;
  //height: 645px;
  background: var(--main-card-color);
  border-radius: 10px;
  position: relative;
  z-index: 1001;
  border: 3px solid #cad2ef;
}

label {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.3px;
  margin: 20px auto 0 auto;
  .required {
    vertical-align: top;
    margin-right: 4px;
  }
  textarea {
    padding: 8px 14px;
    display: block;
    width: 100%;
    height: 154px;
    border: 1px solid var(--auth-input-bg);
    color: var(--main-text-color);
    background: var(--auth-input-bg);
    border-radius: 4px;
    resize: none;
    outline: none;
    margin-top: 3px;
  }
  input {
    padding: 0 8px;
    display: block;
    width: 100%;
    height: 46px;
    border: 1px solid var(--auth-input-bg);
    color: var(--main-text-color);
    background: var(--auth-input-bg);
    border-radius: 4px;
    outline: none;
    margin-top: 3px;
  }
}

input::placeholder,
textarea::placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #9a9a9a;
}

.choose {
  width: 350px;
  height: 46px;
  background-color: var(--auth-input-bg);
  border-radius: 4px;
  margin-top: 3px;
}

.investment {
  width: 96px;
  height: 19px;
  background: #f1f1f3;
  border-radius: 4px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.333333px;
  color: #9a9a9a;
  margin-top: 10px;
}

.create-task {
  width: 235px;
  height: 46px;
  display: block;
  margin: 16px auto 0 auto;
  background: #2c698c;
  border-radius: 4px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #ffffff;
  border: 3px solid #cad2ef;
}

.spinner {
  width: 235px;
  height: 46px;
  display: block;
  margin: 16px auto 0 auto;
  border-radius: 4px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.3px;
}

#error {
  display: none;
}
</style>
